import { PlaySVG } from "../../../../img/components/SVGImages";
import cl from "./VideoBlock.module.css";

const color = "#E8CAAE";


const FirstContentSectionImage = (props) => {
  const clickHandler = () => {
    props.showModal(true);
  };

  return (
    <div className={cl.contentImage}>
      {window.innerWidth > 500 ? (
        <button
          className={`${cl.btn} ${cl.playButtonImageSection}`}
          onClick={clickHandler}
        >
          <PlaySVG color={color} width="120" height="120" />
        </button>
      ) : (
        <button
          className={`${cl.btn} ${cl.playButtonImageSection}`}
          onClick={clickHandler}
        >
          <PlaySVG color={color} width='90' height="90" />
        </button>
      )}

      <img src={process.env.PUBLIC_URL + "/images/VikaNoBgCropped.webp"} alt="" />
      <h2>СЕКРЕТИ МАЙСТРІВ</h2>
    </div>
  );
};

export default FirstContentSectionImage;
