import { useRef} from "react";
import cl from "./CardSlider.module.css";
import cardData from "./CardData";
import PriceCard from "../../blocks/PriceCard/PriceCard";
import SliderButton from "../button/SliderButton";
import { ChevronForwardSVG } from "../../../../img/components/SVGImages";

const CardSlider = () => {
  const scrollRef = useRef();
  const cardRef = useRef();


  const slideTo = (direction) => {
    let cardWidth = cardRef.current.clientWidth;
    cardWidth += window.innerWidth > 320 ? 50 : 10;
    scrollRef.current.scrollLeft +=
      direction === "next" ? cardWidth : -cardWidth;
  };

  const fillSlider = cardData.map((card) => (
    <PriceCard ref={cardRef} key={card.id} card={card} />
  ));
  return (
    <div className={cl.container}>
      <div className={cl.wrapper}>
        {window.innerWidth < 800 && (
          <SliderButton
            onClick={() => slideTo("")}
            previous='previous'
            active="active"
            slider="cardSlider"
          >
            <ChevronForwardSVG />
          </SliderButton>
        )}
        <div ref={scrollRef} className={cl.carousel}>
          {fillSlider}
        </div>
        {window.innerWidth < 800 && (
          <SliderButton
            onClick={() => slideTo("next")}
            active="active"
            slider="cardSlider"
          >
            <ChevronForwardSVG />
          </SliderButton>
        )}
      </div>
    </div>
  );
};
export default CardSlider;
