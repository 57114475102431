import cl from './TextCard.module.css'

const TextCard = (props) => {
  return (
    <div className={cl.textCard}>
      <h3>{props.name}</h3>
      <p>{props.feedback}</p>
    </div>
  );
};

export default TextCard;
