import cl from "./VideoBlock.module.css";

const FirstContentSectionTitle = () => {
  return (
    <div className={cl.contentDescriptionTitle}>
      <h3>Де пройти навчання курсу масажу</h3>
      <h2>
        <b> БЕЗ МЕД. ОСВІТИ</b>
      </h2>
    </div>
  );
};

export default FirstContentSectionTitle;
