import { forwardRef, useContext } from "react";
import SignOnCourseButton from "../../utils/button/SignOnCourseButton";
import cl from "./PriceCard.module.css";
import PriceCardTitle from "./PriceCardTitle";
import PriceCardBody from "./PriceCardBody";
import { Context } from "../../../../context.js";
import { openLeadFormEvent } from "../../utils/Meta/metaPixel.js";

const PriceCard = forwardRef((props, ref) => {
  const { showModal } = useContext(Context);
  const clickHandler = () => {
    showModal();
    // openLeadFormEvent()
  };

  return (
    <div ref={ref} className={`${cl.card} ${cl[props.card.color]}`}>
      <PriceCardTitle card={props.card} />
      <PriceCardBody card={props.card} />
      <SignOnCourseButton onClick={clickHandler}>ЗВ'ЯЗАТИСЯ</SignOnCourseButton>
    </div>
  );
});

export default PriceCard;
