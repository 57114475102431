import React from "react";
import cl from "./Contact.module.css";
import { MapPinSVG, TelSVG} from "../../../../img/components/SVGImages";
import SocialMedia from "./SocialMedia";

const blueMain = '#0893f0'

function Contact(props) {
  const mapLink =
    "https://www.google.com/maps/place/%D0%A1%D0%B5%D0%BA%D1%80%D0%B5%D1%82%D0%B8+%D0%BC%D0%B0%D0%B9%D1%81%D1%82%D1%80%D1%96%D0%B2/@50.6182883,26.2722151,19z/data=!3m1!4b1!4m6!3m5!1s0x472f13e48a36e573:0x48cb9c690e136a92!8m2!3d50.6182883!4d26.2728588!16s%2Fg%2F11nms9475r?authuser=0&entry=ttu";
  const address = "Рівне, вул. Грушевського 44";
  const telNumber = "+38(098)-084-97-83";

  return (
    <div className={cl.contactBlock}>
      <div className={cl.addressBlock}>
        <MapPinSVG />
        <a className={cl[props.textColor]} href={mapLink} target="_blank" rel="noreferrer">
          {address}
        </a>
      </div>
      <div className={cl.telBlock}>
        <TelSVG />
        <a className={cl[props.textColor]} href="tel:+380980849783">{telNumber}</a>
      </div>
      {props.showSocial ? <SocialMedia color={blueMain}/> : null}
    </div>
  );
}

export default Contact;
