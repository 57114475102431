import React, { useEffect, useState } from "react";
import cl from "./TopBar.module.css";
import Contact from "./Contacts/Contact";
import { BigCompanyLogoSVG, SmallCompanyLogoSVG } from "../../../img/components/SVGImages";

const TopBar = (props) => {
  const textColor = 'blackText'
  const width = window.innerWidth;
  const [screenWidth, setWidth] = useState(0);

  useEffect(() => {
    setWidth(width);
  }, [width]);

  const showAppropriateLogo = () => {
    return screenWidth > 600 ? <BigCompanyLogoSVG color='#815A36' /> : <SmallCompanyLogoSVG />;
  };

  return (
    <div
      className={cl.topBar}
    >
      
      <div className={cl.logoBlock}>
        <div className={cl.logoBlock}>{showAppropriateLogo()}</div>
      </div>
      <div className={cl.noMobileNetworkTxt}>
        <h5>
          Шановні клієнти, якщо ми без зв'язку – дзвоніть (пишіть) на Viber /
          Telegram
        </h5>
      </div>
      <Contact textColor={textColor}/>
    </div>
  );
};

export default TopBar;
