import cl from "./FourImageGrid.module.css";

const FourImageGrid = () => {
  return (
    <div className={cl.imagesContainer}>
      <img className={cl.gridImage} src={process.env.PUBLIC_URL + '/images/VikaSchoolNew1.webp'} alt="" />
      <img className={cl.gridImage} src={process.env.PUBLIC_URL + "/images/VikaSchoolNew.webp"} alt="" />
      <img className={cl.gridImage} src={process.env.PUBLIC_URL + "/images/VikaSchoolNew3.webp"} alt="" />
      <img className={cl.gridImage} src={process.env.PUBLIC_URL + "/images/VikaSchoolNew2.webp"} alt="" />
    </div>
  );
};

export default FourImageGrid;
