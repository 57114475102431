import { useState } from "react";
import { PlaySVG } from "../../../../img/components/SVGImages";
import FirstContentSectionImage from "./FirstContentSectionImage";
import FirstContentSectionList from "./FirstContentSectionList";
import FirstContentSectionTitle from "./FirstContentSectionTitle";
import cl from "./VideoBlock.module.css";
import MyModal from "../../utils/modal/MyModal";
import VideoPlayer from "../../utils/VideoPlayer/VideoPlayer";

const color = "#815A36";

const FirstContentSection = () => {
  const [modal, setModal] = useState(false);

  const showModal = (showModal) => {
    setModal(showModal);
  };

  const buttonSize = () => {
    switch (true) {
      case window.innerWidth < 500:
        return <PlaySVG color={color} width="75" height="75" />;

      case window.innerWidth < 800:
        return <PlaySVG color={color} width="100" height="100" />;

      default:
        return <PlaySVG color={color} width="135" height="135" />;
    }
  };
  return (
    <div className={cl.firstSection}>
      <div className={cl.contentDescription}>
        <FirstContentSectionTitle />
        <div className={cl.contentDescriptionList}>
          <button
            className={`${cl.btn} ${cl.playButtonContentSection}`}
            onClick={() => setModal(true)}
          >
            {buttonSize()}
          </button>
          <h4>
            Наші <span>філіали</span>:
          </h4>
          <FirstContentSectionList />
        </div>
      </div>
      <FirstContentSectionImage showModal={showModal} />
      {modal ? (
        <MyModal visible={modal} setVisible={setModal}>
          <VideoPlayer url="https://youtube.com/shorts/WvGm7q2tBMw?si=exB54yq1HpvLxsEm" />
        </MyModal>
      ) : null}
    </div>
  );
};

export default FirstContentSection;
