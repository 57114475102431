import SliderCarousel from "../../utils/Carousel/SliderCarousel";
import { feedBackTextCards } from "./FeedbackData.js";
import cl from "./TextFeedback.module.css";

const TextFeedbackComponent = () => {
  return (
    <div className={cl.textFeedback}>
      <SliderCarousel data={feedBackTextCards()} />
    </div>
  );
};

export default TextFeedbackComponent;
