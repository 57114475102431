import FourImageGrid from "../../utils/FourImageGrid/FourImageGrid";
import Slider from "../../utils/Slider/Slider";
import cl from "./ProgramContentBlock.module.css";
import ProgramContentTitle from "./ProgramContentTitle";

const ProgramContentBlock = () => {
  const programItems = [
    [
      "Покази, протипокази для проведення масажу, вплив на організм, організація роботи.",
      "Класифікація масажу",
      "Анатомо-фізіологічні особливості серцево-судинної, лімфатичної, кісткової і м'язової систем",
      "Практичне відпрацювання рухів погладжування, розтирання, розминання, вібрації",
      "Масаж спини",
    ],
    [
      "Масаж комірцевої ділянки, голови, рук",
      'Масаж обличчя, грудної клітини, живота',
      'Масаж ніг',
      'Загальний масаж',
      'Як реалізувати себе після курсів, екзаменів'
    ],
  ];

  return (
    <div className={cl.programContainer}>
      <div className={cl.programContent}>
        <ProgramContentTitle />
        <div className={cl.programContentWrapper}>
          <FourImageGrid />
          <Slider items={programItems} />
        </div>
      </div>
    </div>
  );
};

export default ProgramContentBlock;
