import cl from "./AboutBlock.module.css";
import React from "react";

const AboutBlock = () => {
  return (
    <div className={cl.about}>
      <h1>
        ПРО <span>КУРС</span>
      </h1>
      <div className={cl.container}>
        {/* <p>
          🌟 Професія "масажист" - шлях до гармонії та фінансової незалежності.
          👐 Масажист - це той, хто допомагає людям відновити здоров'я,
          позбавитися від стресу та покращити самопочуття. Це робота, яка не
          вимагає медичної освіти і може стати вашим власним бізнесом. 🕒
          Вільний графік та свобода у плануванні часу дають можливість бути не
          залежним від роботодавця. 💰 Досвідчені масажисти заробляють від 15000
          гривень та більше. 🌍 Можливість працювати в будь-якій країні з
          мінімальними знаннями мови і фінансовим вкладанням. 📚 На курсах
          "Секрети майстрів" ми навчимо вас найпопулярнішим видам масажу з
          "нуля". Потрібні лише ваше бажання та старанність. 🎓 По завершенні
          навчання ви отримаєте сертифікат міжнародного зразка, який підтверджує
          вашу кваліфікацію. Не шукайте виправдань - знайдіть можливості і
          змініть своє життя вже сьогодні! 🌟 Приєднуйтесь до нашої мережі
          студентів, які успішно працюють як в Україні, так і в Європі. 👥
          Навчайтеся офлайн або онлайн, в групах по 4-6 людей з індивідуальним
          підходом. 👐 Вивчайте 5 найпопулярніших видів масажу та отримайте
          сертифікат міжнародного зразка з фото на двох мовах (UA, ENG).
          Пориньте у світ масажу разом з нами! 🌠👐💆‍♂️💆‍♀️
        </p> */}
        <p>
          Професія <span>"масажист"</span>- що це для кого та як розпочати?
          Давайте розбиратися. Це спеціаліст, який допомагає людям відновити, а
          також зберегти здоров'я, покращити вигляд, позбутися стресів та
          зробити життя більш гармонійним. Звичайно, ця робота не для всіх, але
          при бажанні можна оволодіти навичками за досить невеликий термін та
          без медичної освіти!
          <br />
          <span> Курси Секрети Майстрів</span> дозволяють отримати, як базові
          теоретичні знання, так й пройти практику.
        </p>
        <span>Що дає робота масажиста:</span>
        <p>
          <span>-</span> вільний графік
          <br />
          <span>-</span> змогу планувати свій час, не залежати від роботодавця
          -гарний стабільний дохід( хороші спеціалісти заробляють від{" "}
          <span>15000</span> грн) <br />
          <span>-</span> можливість працювати в будь-якій країні з мінімальним
          знанням мови і фінансовим вкладенням <br />
          <span>-</span> перспективи, можливості розвитку і самовдосконалення
        </p>
        <p>
          На курсах <span>"Секрети майстрів"</span> ми навчаємо з
          <span>"нуля"</span> і <span>без медичної освіти</span> <br />
          Найбільш популярним видам масажу, які ви матимете змогу
          використовувати відразу після проходження навчань. З Вас бажання і
          старання, з нас:
          <br />
          <span>-</span> професійні викладачі,
          <br />
          <span>-</span> відпрацьована авторська методика,
          <br />
          <span>-</span> багато практики,
          <br />
          <span>-</span> підтримка після закінчення курсів,
          <br />
          <span>-</span> удосконалення спеціалістів.
          <br />
          Після проходження навчань Ви отримаєте сертифікат міжнародного зразка
          з фото.
        </p>
        <p>
          <b>
            Не шукайте причини - шукайте можливості і змінюйте своє життя вже
            сьогодні!
          </b>
          <br />
          <br />
          <b>Займатися можна як в офлайн форматі, так і по відео урокам</b>
        </p>
        <p>
          Розпочати можна з курсу <span>"Класичний масаж"- це 12 занять</span>,
          по 2-3 год, які включають необхідну теорію, практичні навчання
          починаються з 2 заняття. На курсі ви оволодієте всіма рухами
          класичного масажу, навчитесь використовувати вагу тіла, засвоїте
          необхідну теорію і зробите масаж всіх ділянок тіла, включаючи
          загальний масаж. Ціна курсу <span>7000</span> грн.
        </p>
        <p>
          Курс <span>"Масажист-універсал"</span> підійде для тих, хто хоче
          засвоїти одразу кілька необхідних видів масажу і мати гарні навички
          для старту своєї справи, або конкурентні переваги для роботодавця.
          Курс складається з 16 занять по 2-3 год, та включає в себе класичний
          масаж, антицелюлітний, медовий, баночний і тейпування. Після
          закінчення Ви зможете запропонувати своїм клієнтам різні види послуг і
          станете впевненим майстром. Ціна курсу <span>9000</span> грн.
        </p>
      </div>
    </div>
  );
};

export default AboutBlock;
