import cl from "./ProgramContentBlock.module.css";

const ProgramContentTitle = () => {
  return (
    <div className={cl.programContentTitle}>
      <h1>
        План курсу <span>"Класичний масаж"</span>
      </h1>
      <p>
        В основі курсу <span>"Класичний масаж"</span> лежать реальні вимоги до масажного ринку. Ми надаємо
        лише актуальну інформацію та практичні навички, які роблять наших
        випускників компетентними у вирішенні завдань реальних клієнтів.
      </p>
    </div>
  );
};

export default ProgramContentTitle;
