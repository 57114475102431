import { useState } from "react";
import cl from "./FeedbackSwitcher.module.css";

const FeedbackSwitcherComponent = (props) => {
  const [activeTitle, setActiveTitle] = useState(true);

  const handleClick = (isTextFeedback) => {
    setActiveTitle(isTextFeedback)
    props.changeSection(isTextFeedback)
  };
  return (
    <div className={cl.switcherContainer}>
      <button
        className={activeTitle === true ? cl.active : ''}
        onClick={() => handleClick(true)}
      >
        Відгуки
      </button>
      <button
        className={`${cl.videoButton} ${activeTitle === false ? cl.active : ''}`}
        onClick={() => handleClick(false)}
      >
        Відео Відгуки
      </button>
    </div>
  );
};

export default FeedbackSwitcherComponent;
