import { ForwardArrow } from "../../../../img/components/SVGImages";
import SliderButton from "../button/SliderButton";
import cl from './Slider.module.css'

const SliderButtonContainer = (props) => {

  const handleClick = (move) => {
    props.handleClick(move)
  };


  return (
    <div className={cl.sliderButtons}>
      {props.slide > 0 ? (
        <SliderButton
          onClick={() => handleClick(-1)}
          active={props.active}
          previous="previous"
        >
          <ForwardArrow />
        </SliderButton>
      ) : (
        <SliderButton disabled={true} active="" previous="previous">
          <ForwardArrow />
        </SliderButton>
      )}
      {props.slide < props.items.length - 1 ? (
        <SliderButton onClick={() => handleClick(1)} active={props.active}>
          <ForwardArrow />
        </SliderButton>
      ) : (
        <SliderButton disabled={true} active="">
          <ForwardArrow />
        </SliderButton>
      )}
    </div>
  );
};

export default SliderButtonContainer;
