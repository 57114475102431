import { QuoteSVG } from "../../../../img/components/SVGImages";
import cl from "./VideoBlock.module.css";

const LastSection = () => {
  return (
    <div className={cl.videoLastSection}>
      <div className={cl.lastSectionContainer}>
        <QuoteSVG />
        <div className={cl.lastSectionText}>
          <p>Масажистами не народжуються – ними стають!</p>
          <p>
            Бажання, вільний час та гарний настрій – це все, що необхідно, щоб
            освоїти масаж.
          </p>
          <p>Найстаршому нашому випускнику 83 роки, наймолодшому – 14 років.</p>
          <p>Вчитися ніколи не пізно та ніколи не рано!</p>
        </div>
      </div>
    </div>
  );
};

export default LastSection;
