import { useState } from "react";
import cl from "./SliderCarousel.module.css";
import SliderButton from "../button/SliderButton";
import { ForwardArrow } from "../../../../img/components/SVGImages";

const SliderCarousel = (props) => {
  const [current, setCurrent] = useState(0);
  console.log(props.data);
  const slideRight = () => {
    setCurrent(current === props.data.length - 1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? props.data.length - 1 : current - 1);
  };

  return (
    <div className={cl.sliderCarousel}>
      <SliderButton previous='previous' active='active' onClick={slideLeft}><ForwardArrow/></SliderButton>
     {props.data[current]}
      <SliderButton active='active' onClick={slideRight}><ForwardArrow/></SliderButton>
    </div>
  );
};

export default SliderCarousel;
