import { useState } from "react";

const useForm = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    e.persist();

    let name = e.target.name;
    let value = e.target.value;

    validate(name, value);

    setValues({
      ...values,
      [name]: value,
    });
  };

  const validate = (name, value) => {
    switch (name) {
      case "name":
        if (value.length <= 2) {
          setErrors({
            ...errors,
            name: "Ім'я має бути не менше 3 літер",
          });
        } else {
          const { name, ...withoutError } = errors;
          setErrors(withoutError);
        }
        break;
      case "phone":
        if (
          !new RegExp(/^\+?\d{0,2}\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{2}?[-.\s]?\d{2}?/).test(value)
        ) {
          setErrors({
            ...errors,
            phone: "Введіть правильний номер телефону",
          });
        } else {
          const { phone, ...withoutError } = errors;
          setErrors(withoutError);
        }
        break;
        case 'email':
          if (!new RegExp(/\S+@\S+\.\S+/).test(value)) {
            setErrors({
              ...errors,
              email: "Введіть правильну поштову адресу"
            });
          } else {
            const {email, ...withoutError } = errors;
            setErrors(withoutError)
          }
          break;
      default:
        break;
    }
  };

  return {
    values,
    errors,
    handleChange,
  };
};

export default useForm;
