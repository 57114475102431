import React from 'react';
import cl from './MyModal.module.css';
import { Context } from '../../../../context.js'
import { useContext } from 'react';

const MyModal = ({children, visible, setVisible}) => {

    const rootClasses = [cl.myModal]
    const { hideModal } = useContext(Context);
    

    if (visible) {
        rootClasses.push(cl.active);
    }


    const clickHandler = () => {
        hideModal()
        setVisible(false)
    }

    return (
        <div className={rootClasses.join(' ')} onClick={clickHandler}>
            <div className={cl.myModalContent} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default MyModal;