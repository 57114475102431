import { useEffect, useState } from "react";
import cl from "./Slider.module.css";
import SliderButtonContainer from "./SliderButtonContainer";

const FADE_DURATION = 300;

const Slider = (props) => {
  const [slide, setSlide] = useState(0);
  const [fadeState, setFadeState] = useState("fade-in");
  const [active, setActive] = useState("active");
  const [currentTimer, setCurrentTimer] = useState(null);

  const handleClick = (move) => {
    const timer = setTimeout(() => {
      setSlide((s) => s + move);
      setFadeState("fade-in");
    }, FADE_DURATION);
    clearTimeout(currentTimer);
    setFadeState("fade-out");
    setCurrentTimer(timer);
  };

  useEffect(() => {
    (slide === 0 || slide === 1) && setActive("active");
  }, [slide]);

  const fillList = (items) => {
    const item = items[slide];
    return (
      <ul>
        {item.map((element, index) => (
          <li key={index}>{element}</li>
        ))}
      </ul>
    );
  };
  return (
    <div className={cl.slider}>
      <div className={`${cl.slide} ${cl[fadeState]}`}>
        {fillList(props.items)}
      </div>
      <hr />
      <SliderButtonContainer
        active={active}
        slide={slide}
        handleClick={handleClick}
        items={props.items}
      />
    </div>
  );
};

export default Slider;
