import ReactPlayer from "react-player/youtube";
import cl from "./VideoPlayer.module.css";
import Loader from "../Loader/Loader";
import { useState } from "react";

const VideoPlayer = (props) => {
const [loader, setLoader] = useState(true)

  return (
    <div className={cl["player-wrapper"]}>
      {loader ? <Loader width='100%' height='100%'/> : null}
      <ReactPlayer
        url={props.url}
        controls={true}
        onReady={() => setLoader(false)}
        playing="true"
      />
    </div>
  );
};

export default VideoPlayer;
