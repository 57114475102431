import VideoPlayer from "../../utils/VideoPlayer/VideoPlayer";
import TextCard from "./TextCard";
import VideoCard from "./VideoCard";

export const videoFeedback = [
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/playlist?list=PL_CjrFgbGTmjrzH_6Ks2D4KtiQDuCDA1U&si=v6WoXsyv2Mq-A418" />
    }
  />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/3AF9fGJhwzk?si=_MU7AKtnvAgvRLTH" />
    }
  />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/AycHDbVviKE?si=4IdtbkmTT2fE2WUf" />
    }
  />,
  // <VideoCard
  //   player={
  //     <VideoPlayer url="https://youtube.com/shorts/UHcXKuySMDs?si=XMBXimzdUO8gMG9I" />
  //   }
  // />,
  // <VideoCard
  //   player={
  //     <VideoPlayer url="https://youtube.com/shorts/BokC2xR_8js?si=F9h_cR-UHhideeH1" />
  //   }
  // />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/AuOPsRQx8-w?si=HA2gXyPY5ODYrX3f" />
    }
  />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/UHcXKuySMDs?si=_hau5uXyrFHkrr2o" />
    }
  />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/6d3Gg8ljIt4?si=_kolrXBhtL67Mr4O" />
    }
  />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/ZuBg03YWz0M?si=jIn19dx0ns0taSYW" />
    }
  />,
  <VideoCard
    player={
      <VideoPlayer url="https://youtube.com/shorts/BokC2xR_8js?si=f8qvZJ37ZLPbzt19" />
    }
  />,
  // <VideoCard
  //   player={<VideoPlayer url="https://www.youtube.com/watch?v=hXyg-4IDsPg" />}
  // />,
];

export const textFeedback = [
  {
    name: "Cвітлана Мокрик",
    feedback:
      "Вітаю! Дуже дякую за таке навчання і нову сходинку. Дуже хороший був в нас учитель наставник і просто людина з великої літери - так це про вас Вікторія",
  },
  {
    name: "Людмила Матвійчук",
    feedback:
      "Найкраща - школа масажу!!! Яка не тільки вчить, але дає тобі впевненість у собі!",
  },
  {
    name: "Віктор Іванишин",
    feedback:
      "Все сподобалось, всім задоволена! Неодмінно ще прийду навчатися на інші кваліфікації, якщо звичайно можна",
  },
  {
    name: "Soffi",
    feedback:
      "Доброго вечора, буду дуже вдячна. Я також приєднуючись до попередніх відгуків хочу подякувати Вам за цей курс масажу. За зусилля які ви приклали, навчаючи нас, за те що розказали й показали нам тонкощі масажу. Успіхів у подальшому вашому житті",
  },
  {
    name: "Юля Вараш",
    feedback:
      "Дякуємо Вікторії за навчання,та хорошу атмосферу, тому рекомендую підвищувати свою кваліфікацію",
  },
  {
    name: "Даша",
    feedback:
      "Я вдячна за цей курс усім, ви мене просто реабілітували від негативу",
  },
  {
    name: "Лідія",
    feedback: "Дякую Вікторія за навчання, цей масаж реально крутий",
  },
  {
    name: "Анжеліка Рудик",
    feedback:
      "Я медична сестра, вийшла на пенсію по медичному стажу і вирішила спробувати себе в масажі. Я обрала школу масажу `Секрети майстрів` після спілкування з викладачем. Курсами задоволена, програма мені зрозуміла, боялась, що не запам'ятаю все, але до кінця навчань матеріал засвоївся. Було багато практики, ми всі отримали масаж, бо відпрацьовували один на одному( це приємний бонус)",
  },
  {
    name: "Андрій Остапчук",
    feedback:
      "Секрети майстрів- найкраща школа масажу, яка не тільки навчає професії, а й дає впевненість в собі! Дякую!",
  },
  {
    name: "Anna",
    feedback:
      "Я почала практикувати до закінчення курсів, люди йдуть задоволені. Сподіваюсь масаж стане моєю новою професією. Рекомендую курси `Секрети майстрів` - тут справді вчать!",
  },
  {
    name: "Ольга Красуня",
    feedback:
      "Давно мріяла навчитись робити масаж, до курсів пробувала на своїх, але тільки тільки коли пройшла навчання зрозуміла всі нюанси, побачила свої помилки. Приємно вражена професійністю і увагою викладача. Дуже вдячна за отриманий досвід!",
  },
];

export const feedBackTextCards = () => {
  return textFeedback.map((textCard) => {
    return <TextCard name={textCard.name} feedback={textCard.feedback} />;
  });
};
