import cl from "./SignUpToCourse.module.css";
import SignOnCourseButton from "../../utils/button/SignOnCourseButton";
import { Context } from "../../../../context.js";
import { useContext } from "react";
import { openLeadFormEvent } from "../../utils/Meta/metaPixel.js";

const SignUpToCourseBlock = () => {
  function signOnCourse() {
    // openLeadFormEvent()
    showModal();
  }

  const { showModal } = useContext(Context);

  return (
    <div className={cl.container}>
      <div className={cl.main}>
        <h1>
          СТАНЬ ПРОФЕСІЙНИМ МАСАЖИСТОМ – <span>ЗМІНИ СВОЄ ЖИТТЯ!</span>
        </h1>
        <div className={cl.signOn}>
          <p>
            Іде набір до групи. <br />
            Залишіть заявку зараз!
          </p>
          <SignOnCourseButton onClick={() => signOnCourse()} blue="blue">
            ЗВ'ЯЗАТИСЯ
          </SignOnCourseButton>
        </div>
      </div>
      <img src={process.env.PUBLIC_URL + "/images/Vika.webp"} alt="" />
    </div>
  );
};

export default SignUpToCourseBlock;
