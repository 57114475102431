import CardSlider from "../utils/Slider/CardSlider";
import cl from "./PriceBlock.module.css";

const PriceBlock = () => {
  return (
    <div className={cl.priceBlock}>
      <h2>
        ВАРТІСТЬ <span>НАВЧАННЯ</span>
      </h2>
      <CardSlider />
    </div>
  );
};

export default PriceBlock;
