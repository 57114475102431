const Loader = (props) => {

  const styles = {
    width: props.width,
    height: props.height,
    position: "absolute",
    top: "0",
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div style={styles}>
      <img src={process.env.PUBLIC_URL + "/images/loader.svg"} alt="" />
    </div>
  );
};

export default Loader;
