const cardData = [
  {
    id: 1,
    courseDuration: "Класичний масаж",
    studyDays: "12 занять 3 рази на тиждень",
    price: "7000 грн",
    studyTime: "Заняття 2-3 години",
    studyLanguage: "Українська",
    studyAge: "14-75 років",
    suitableFor:
      "Базовий курс для початку масажної практики, або якщо ви хочете освоїти масаж для себе",
    studyGroups: "Навчання відбувається у малих групах від 4-6 осіб.",
    color: "white",
  },
  {
    id: 2,
    courseDuration: "Масажист-універсал",
    studyDays: "16 занять 3 рази на тиждень",
    price: "9000 грн",
    studyTime: "Заняття 2-3 години",
    studyLanguage: "Українська",
    studyAge: "14-75 років",
    suitableFor:
      "Хочете навчитись найбільш популярним масажним технікам- цей курс для Вас. Ви зможете пропонувати послуги для клієнтів з різними запитами.",
    studyGroups: "Навчання відбувається у малих групах від 4-6 осіб.",
    color: "white",
  },
  {
    id: 3,
    courseDuration: "Масаж prof",
    studyDays: "16 занять 3 рази на тиждень",
    price: "10 000 грн",
    studyTime: "Заняття 2-3 години",
    studyLanguage: "Українська",
    studyAge: "14-75 років",
    suitableFor:
      "Цей курс орієнтований на виявлення і зняття больових синдромів, корекцію тазу, роботу з захворюваннями опорно-рухового апарату",
    studyGroups: "Навчання відбувається у малих групах від 4-6 осіб.",
    color: "white",
  },
];

export default cardData;
