import { useState } from "react";
import cl from "./Feedback.module.css";
import FeedbackSwitcherComponent from "./FeedbackSwitcherComponent";
import TextFeedbackComponent from "./TextFeedbackComponent";
import VideoFeedbackComponent from "./VideoFeedbackComponent";

const FeedbackBlock = () => {

   const [section, setSection] = useState('text')

   const changeSection = (isTextFeedback) => {
    isTextFeedback ? setSection("text") : setSection("video");
   }

  return (
    <div className={cl.feedbackBlock}>
      <h1>Відгуки</h1>
      <FeedbackSwitcherComponent changeSection={changeSection}/>
      {section === 'text' ? <TextFeedbackComponent /> : <VideoFeedbackComponent />}
      
      
    </div>
  );
};

export default FeedbackBlock;
