import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import styles from "./Toast.module.css";


const Toast = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showToast
  }))

  const [list, setList] = useState([]);
  let toastProperties = null;
    const showToast = (type, title, description) => {
      switch (type) {
        case "success":
          toastProperties = {
            id: list.length + 1,
            title: title,
            description: description,
            backgroundColor: "#5cb85c",
          };
          break;
        case "danger":
          toastProperties = {
            id: list.length + 1,
            title: title,
            description: description,
            backgroundColor: "#d9534f",
          };
          break;
        case "info":
          toastProperties = {
            id: list.length + 1,
            title: title,
            description: description,
            backgroundColor: "#5bc0de",
          };
          break;
        case "warning":
          toastProperties = {
            id: list.length + 1,
            title: title,
            description: description,
            backgroundColor: "#f0ad4e",
          };
          break;
        default:
          toastProperties = [];
      }
      setList([...list, toastProperties]);
    };

  const deleteToast = useCallback(
    (id) => {
      const toastListItem = list.filter(e => e.id !== id);
      setList(toastListItem);
    },
    [list, setList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length) {
        deleteToast(list[0].id);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [list, deleteToast]);

  return (
    <div className={`${styles.container} ${styles[props.position]}`}>
      {list.map((toast, i) => (
        <div
          key={i}
          className={`${styles.notification} ${styles.toast} ${styles[props.position]}`}
          style={{ backgroundColor: toast.backgroundColor }}
        >
          {/* <button onClick={(e) => deleteToast(e, toast.id)}>X</button> */}
          <div>
            <p className={styles.title}>{toast.title}</p>
            <p className={styles.description}>{toast.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}) 

export default Toast;
