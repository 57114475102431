import cl from "./ForWhomBlock.module.css";

const ForWhomBlock = () => {
  return (
    <div className={cl.main}>
      <h1>
        <span>ДЛЯ КОГО</span> СТВОРЕНО ПРОГРАМУ
      </h1>
      <div className={cl.container}>
        <div className={cl.item}>
          <h3>Представники різних професій</h3>
          <img src="/images/massageForNew3.webp" alt="" />
          <p>
            Для людей різних професій, які хочуть перекваліфікуватись і швидко
            здобути нову, потрібну спеціальність і заробляти не залежачи від
            обставин
          </p>
        </div>
        <div className={cl.item}>
          <h3>Студентів ВНЗ та мед працівників</h3>
          <img src="/images/massageForNew2.webp" alt="" />
          <p>
            Для медичних працівників і студентів, які бажають мати додатковий
            або розширити спектр своїх послуг.
          </p>
        </div>
        <div className={cl.item}>
          <h3>Для працевлаштування за кродоном</h3>
          <img src="/images/massageForNew1.webp" alt="" />
          <p>
            Для людей, які планують виїзд за кордон і потребують швидкого
            працевлаштування без гарного знання мови.
          </p>
        </div>
        <div className={cl.item}>
          <h3>Для себе</h3>
          <img
            src={process.env.PUBLIC_URL + "/images/massageForNew4.webp"}
            alt=""
          />
          <p>
            Курси масажу можуть проходити люди, які не планують працювати
            масажистами, для потреб своєї родини або для особистого
            самовдосконалення
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForWhomBlock;
