import {
  PeopleSVG,
  TimeSVG,
  WorldSVG,
} from "../../../../img/components/SVGImages";
import cl from "./PriceCard.module.css";

const PriceCardBody = (props) => {
  return (
    <div className={cl.body}>
      <h3>{props.card.price}</h3>
      <div className={cl.textWithIcon}>
        <TimeSVG />
        <p>{props.card.studyTime}</p>
      </div>
      <div className={cl.textWithIcon}>
        <WorldSVG />
        <p>{props.card.studyLanguage}</p>
      </div>
      <div className={cl.textWithIcon}>
        <PeopleSVG />
        <p>{props.card.studyAge}</p>
      </div>

      <p className={cl.description}>
        {props.card.suitableFor}
        <br />
        <br />
       {props.card.studyGroups}
      </p>
    </div>
  );
};

export default PriceCardBody;


