import FirstContentSection from "./FirstContentSection";
import LastSection from "./LastSection";
import cl from "./VideoBlock.module.css";

const VideoBlock = () => {
  return (
    <div className={cl.container}>
      <div className={cl.content}>
        <FirstContentSection />
        <LastSection />
      </div>
    </div>
  );
};

export default VideoBlock;
