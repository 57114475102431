import { BigCompanyLogoSVG } from "../../../../img/components/SVGImages";
import Contact from "../Contacts/Contact";
import SocialMedia from "../Contacts/SocialMedia";
import cl from "./Footer.module.css";

const whiteColor = "white";

const Footer = () => {

  const textColor = "whiteText";
  const currentYear = new Date().getFullYear();
  return (
    <div className={cl.container}>
      <div className={cl.mainSection}>
        <div className={cl.logoAndSocial}>
          <BigCompanyLogoSVG color={whiteColor} />
          <SocialMedia color={whiteColor} />
        </div>
        <div>
          <Contact showSocial={false} textColor={textColor} />
        </div>
      </div>
      <h6>
        &#xA9; 2010-{currentYear} Секрети Майстрів&#x2122;. Усі права захищені.
      </h6>
    </div>
  );
};

export default Footer;
