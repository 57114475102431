import { useContext } from "react";
import SignOnCourseButton from "../utils/button/SignOnCourseButton";
import cl from "./MainBlock.module.css";
import { Context } from "../../../context.js";
import { openLeadFormEvent } from "../utils/Meta/metaPixel.js";

const MainBlock = () => {
  const { showModal } = useContext(Context);

  function signOnCourse() {
    // openLeadFormEvent()
    showModal();
  }

  return (
    <div className={cl.main}>
      <div className={cl.description}>
        <h1>
          НАВЧАННЯ МАСАЖУ З "НУЛЯ" - <span>БЕЗ МЕДИЧНОЇ ОСВІТИ!</span>
        </h1>
      </div>
      <div className={cl.bonuses}>
        <h4>
          <span>+ </span>навчання в маленьких групах або індивідуально
        </h4>
        <h4>
          <span>+ </span>надаємо роздруковані лекції, забезпечуємо усім
          необхідним
        </h4>
        <h4>
          <span>+ </span>практика займає 80% часу занять
        </h4>
        <h4>
          <span>+ </span> Ваш попередній досвід, спеціальність чи вік не мають
          значення, головне - бажання
        </h4>
        <h4>
          <span>+ </span>після здачі іспиту Ви отримаєте сертифікат міжнародного
          зразка, з яким можна працевлаштуватись як в Україні так і закордоном
        </h4>
        <h4>
          <span>+ </span>навчатись можна по відео урокам в зручний час
        </h4>
      </div>
      <div>
        <SignOnCourseButton onClick={() => signOnCourse()}>
          ЗВ'ЯЗАТИСЯ
        </SignOnCourseButton>
      </div>
    </div>
  );
};

export default MainBlock;
