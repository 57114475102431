import useNetwork from "../../../hooks/useNetwork";
import cl from "./NoInternet.module.css";
import { NoWifiSVG} from "../../../../img/components/SVGImages";

const NoInternet = () => {
  const networkState = useNetwork();
  const { online } = networkState;

  return (
    <div className={cl.container}>
      {online ? null : (
        <div className={`${cl.connection} ${cl.offline}`}>
          <NoWifiSVG />
          <p>Підключення до інтернету відсутнє</p>
        </div>
      )}
    </div>
  );
};

export default NoInternet;
