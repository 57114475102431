import cl from './VideoFeedback.module.css'
import { videoFeedback } from './FeedbackData'
import SliderCarousel from '../../utils/Carousel/SliderCarousel'


const VideoFeedbackComponent = () => {
 return   <div className={cl.videoFeedback}>
    <SliderCarousel data={videoFeedback}/>
</div>
}

export default VideoFeedbackComponent