import { FacebookLogoSVG, InstagramLogoSVG, YouTubeLogoSVG } from "../../../../img/components/SVGImages"
import cl from './SocialMedia.module.css'

const SocialMedia = (props) => {
 return <div className={cl.socialMedia}>
 <a
   href="https://www.facebook.com/sekretov.net.ua1/"
   target="_blank"
   rel="noreferrer"
 >
   <FacebookLogoSVG color={props.color}/>
 </a>
 <a
   href="https://www.instagram.com/sekrets_of_masters/"
   target="_blank"
   rel="noreferrer"
 >
  <InstagramLogoSVG color={props.color}/>
 </a>
 <a
   href="https://www.youtube.com/channel/UCuNTXKJWtZlTZnVs7oTOTlg"
   target="_blank"
   rel="noreferrer"
 >
   <YouTubeLogoSVG color={props.color}/>
 </a>
</div>
}

export default SocialMedia