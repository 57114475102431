import cl from "./PriceCard.module.css";

const PriceCardTitle = (props) => {
  return (
    <div className={cl.courseDuration}>
      <h4>{props.card.courseDuration}</h4>
      <p>{props.card.studyDays}</p>
    </div>
  );
};

export default PriceCardTitle;



